import React from "react";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import "./fontello.css";
import "./App.css";

const theme = {
  contentWidth: 1200,

  headerFontFamily: `"Lucida Grande","Lucida Sans Unicode","Lucida Sans",Geneva,Arial,sans-serif`,
  bodyFontFamily: `Georgia,Cambria,"Times New Roman",Times,serif`
};

function App({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {children}
      </Wrapper>
    </ThemeProvider>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export default App;
