import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Frame } from "./Frame";
import { CookieLaw } from "./CookieLaw";

function Footer() {
  return (
    <Wrapper>
      <Content>
        <CookieLaw />
        <Footnote>
          <FootnoteText>©{(new Date()).getFullYear()} Michal Zalecki</FootnoteText>
          <FootnoteLink to="/privacy-policy/">Privacy Policy</FootnoteLink>
          <FootnoteLink to="/contact/">Contact</FootnoteLink>
          <FootnoteSocialGroup>
            <FootnoteSocialLink href="https://twitter.com/MichalZalecki" title="Twitter" target="_blank" rel="noopener"><i className="icon-twitter-1" /></FootnoteSocialLink>
            <FootnoteSocialLink href="https://github.com/MichalZalecki" title="GitHub" target="_blank" rel="noopener"><i className="icon-github-circled" /></FootnoteSocialLink>
            <FootnoteSocialLink href="/rss.xml" title="RSS Feed" target="_blank" rel="noopener"><i className="icon-rss" /></FootnoteSocialLink>
          </FootnoteSocialGroup>
        </Footnote>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  margin-top: auto;
  background: #000;
  color: #FFF;
`;

const Content = styled(Frame)`
  font-size: 14px;
  line-height: 16px;
  font-family: ${props => props.theme.headerFontFamily};
`;

const Footnote = styled.div`
  margin: 30px 0;
  display: flex;
  & > * {
    display: inline-block;
    vertical-align: middle;
    line-height: 22px;
  }
  @media (max-width: 580px) {
    display: block;
  }
`;

const FootnoteText = styled.span`
  text-shadow: none;
  background-image: none;
  color: #FFF;
`;

const FootnoteLink = styled(Link)`
  text-shadow: none;
  background-image: none;
  color: #FFF;
  &:before {
    content: "|";
    color: #FFF;
    margin: 0 10px;
  }
`;

const FootnoteSocialLink = styled.a`
  text-shadow: none;
  background-image: none;
  color: #FFF;
  font-size: 22px;
`;

const FootnoteSocialGroup = styled.div`
  margin-left: auto;
  display: block;
  @media (max-width: 580px) {
    margin-top: 10px;
  }
`;

export default Footer;
