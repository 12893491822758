import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { postPath } from "../utils/paths";

function getTitle(site, post = { frontmatter: {} }, page = {}) {
  const { siteName, title } = site.siteMetadata;
  const prefix = page.pageNumber > 1 ? `Page: ${page.pageNumber} - ` : "";
  if (post.frontmatter.title || page.title) {
    return `${post.frontmatter.title || page.title} - ${siteName}`;
  }
  return `${prefix}${title}`;
}

function getDescription(post = { frontmatter: {} }) {
  if (post.frontmatter.description) {
    return post.frontmatter.description
  };
  return null;
}

function getURL(site, post = { fields: {} }, page = {}) {
  const { siteUrl } = site.siteMetadata;
  if (post.fields.slug) {
    return `${siteUrl}${postPath(post.fields.slug)}`;
  }
  if (page.pathname) {
    return `${siteUrl}${page.pathname}`;
  }
  throw new Error("No URL for SEO");
}

function getCanonical(site, post = { fields: {}, frontmatter: {} }, page = {}) {
  if (post && post.frontmatter.canonical) return post.frontmatter.canonical;
  return getURL(site, post, page);
}

function getImage(site, post, defaultImage) {
  const { siteUrl } = site.siteMetadata;
  if (post && post.frontmatter.hero) return `${siteUrl}${post.frontmatter.hero.childImageSharp.og.src}`;
  return `${siteUrl}${defaultImage.childImageSharp.fixed.src}`;
}

function SEO({ post, page }) {
  const description = getDescription(post);
  const isPost = !!post || null;

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              siteUrl
              siteName
              twitter
            }
          }
          defaultImage: file(relativePath: { eq: "michalzalecki.jpg" }) {
            childImageSharp {
              fixed(width: 630, height: 630) {
                src
              }
            }
          }
        }
      `}
      render={({ defaultImage, site }) => (
        <Helmet>
          <html lang="en" />
          <title>{getTitle(site, post, page)}</title>
          <link rel="canonical" href={getCanonical(site, post, page)} />
          {isPost && description && <meta name="description" content={description} />}

          {/* OpenGraph */}
          {isPost && <meta property="og:type" content="article" />}
          {isPost && <meta property="og:title" content={getTitle(site, post, page)} />}
          {isPost && description && <meta property="og:description" content={description} />}
          {isPost && <meta property="og:url" content={getURL(site, post, page)} />}
          <meta property="og:image" content={getImage(site, post, defaultImage)} />
          <meta property="og:site_name" content={site.siteMetadata.siteName} />

          {/* Twitter Card */}
          {isPost && <meta name="twitter:card" content="summary_large_image" />}
          {!isPost && <meta name="twitter:card" content="summary" />}
          {isPost && <meta name="twitter:creator" content={site.siteMetadata.twitter} />}
          <meta name="twitter:site" content={site.siteMetadata.twitter} />
          {/* TODO <meta name="twitter:image:alt" content="Alt text for image" /> */}

          {/* Favicon */}
          <link rel="apple-touch-icon-precomposed" sizes="57x57" href={`${site.siteMetadata.siteUrl}/apple-touch-icon-57x57.png`} />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href={`${site.siteMetadata.siteUrl}/apple-touch-icon-114x114.png`} />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href={`${site.siteMetadata.siteUrl}/apple-touch-icon-72x72.png`} />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href={`${site.siteMetadata.siteUrl}/apple-touch-icon-144x144.png`} />
          <link rel="apple-touch-icon-precomposed" sizes="60x60" href={`${site.siteMetadata.siteUrl}/apple-touch-icon-60x60.png`} />
          <link rel="apple-touch-icon-precomposed" sizes="120x120" href={`${site.siteMetadata.siteUrl}/apple-touch-icon-120x120.png`} />
          <link rel="apple-touch-icon-precomposed" sizes="76x76" href={`${site.siteMetadata.siteUrl}/apple-touch-icon-76x76.png`} />
          <link rel="apple-touch-icon-precomposed" sizes="152x152" href={`${site.siteMetadata.siteUrl}/apple-touch-icon-152x152.png`} />
          <link rel="icon" type="image/png" href={`${site.siteMetadata.siteUrl}/favicon-196x196.png`} sizes="196x196" />
          <link rel="icon" type="image/png" href={`${site.siteMetadata.siteUrl}/favicon-96x96.png`} sizes="96x96" />
          <link rel="icon" type="image/png" href={`${site.siteMetadata.siteUrl}/favicon-32x32.png`} sizes="32x32" />
          <link rel="icon" type="image/png" href={`${site.siteMetadata.siteUrl}/favicon-16x16.png`} sizes="16x16" />
          <link rel="icon" type="image/png" href={`${site.siteMetadata.siteUrl}/favicon-128.png`} sizes="128x128" />
          <meta name="application-name" content={site.siteMetadata.siteName} />
          <meta name="msapplication-TileColor" content="#FFCE00" />
          <meta name="msapplication-TileImage" content={`${site.siteMetadata.siteUrl}/mstile-144x144.png`} />
          <meta name="msapplication-square70x70logo" content={`${site.siteMetadata.siteUrl}/mstile-70x70.png`} />
          <meta name="msapplication-square150x150logo" content={`${site.siteMetadata.siteUrl}/mstile-150x150.png`} />
          <meta name="msapplication-wide310x150logo" content={`${site.siteMetadata.siteUrl}/mstile-310x150.png`} />
          <meta name="msapplication-square310x310logo" content={`${site.siteMetadata.siteUrl}/mstile-310x310.png`} />
        </Helmet>
      )}
    />
  );
}

export default SEO;
