import React, { Component } from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Sticky from "react-stickynode";
import styled from "styled-components";

class Header extends Component {
  state = {
    showMobileMenu: false,
  };

  openMobileMenu = () => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
    this.setState({ showMobileMenu: true }, () => {
      this.mobileMenu.focus();
    });
  }

  closeMobileMenu = () => {
    document.body.style.overflow = "";
    document.body.style.height = "";
    this.setState({ showMobileMenu: false });
  }

  refMobileMenu = (el) => {
    this.mobileMenu = el;
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            file(relativePath: { eq: "michalzalecki.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 76, maxHeight: 76, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <Wrapper>
            <Frame>
              <Content>
                <Lead>
                  <Logo to="/" title="Home page">
                    <Img fadeIn={false} critical={true} fluid={data.file.childImageSharp.fluid} alt="Michal Zalecki" />
                  </Logo>
                  <LeadText>
                    <Title>Michal Zalecki</Title>
                    <Subtitle>software development, testing, JavaScript,<br />TypeScript, Node.js, React, and other stuff</Subtitle>
                  </LeadText>
                  <MobileMenuButton title="Open mobile menu" onClick={this.openMobileMenu}>
                    <i className="icon-menu" />
                  </MobileMenuButton>
                </Lead>
              </Content>
            </Frame>
            <Sticky top={-26} enabled>
              {({ status }) => (
                <NavWrapper fixed={status === Sticky.STATUS_FIXED}>
                  <Frame>
                    <Nav fixed={status === Sticky.STATUS_FIXED}>
                      <NavLink to="/">Home</NavLink>
                      <NavLink to="/about/">About</NavLink>
                      <NavLink to="/contact/">Contact</NavLink>
                      <NavSocialLink href="https://twitter.com/MichalZalecki" title="Twitter" target="_blank" rel="noopener" style={{ marginLeft: "auto" }}><i className="icon-twitter-1" /></NavSocialLink>
                      <NavSocialLink href="https://github.com/MichalZalecki" title="GitHub" target="_blank" rel="noopener"><i className="icon-github-circled" /></NavSocialLink>
                      <NavSocialLink href="/rss.xml" title="RSS Feed" target="_blank" rel="noopener"><i className="icon-rss" /></NavSocialLink>
                    </Nav>
                  </Frame>
                </NavWrapper>
              )}
            </Sticky>
            <MobileMenu
              open={this.state.showMobileMenu}
              tabIndex={this.state.showMobileMenu ? "-1" : undefined}
              ref={this.refMobileMenu}
            >
              <Content>
                <Lead>
                  <div />
                  <div />
                  <MobileMenuButton title="Open mobile menu" onClick={this.closeMobileMenu}>
                    <i className="icon-menu" />
                  </MobileMenuButton>
                </Lead>
                <nav>
                  <MobileMenuList>
                    <li><NavLink to="/" onClick={this.closeMobileMenu}>Home</NavLink></li>
                    <li><NavLink to="/about/" onClick={this.closeMobileMenu}>About</NavLink></li>
                    <li><NavLink to="/contact/" onClick={this.closeMobileMenu}>Contact</NavLink></li>
                  </MobileMenuList>
                </nav>
              </Content>
            </MobileMenu>
          </Wrapper>
        )}
      />
    );
  }
}

const Wrapper = styled.header`
  background: #FFCE00;
  @media (max-width: 580px) {
    margin-bottom: -26px;
  }
`;

const Frame = styled.div`
  max-width: ${props => props.theme.contentWidth}px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
`;

const Content = styled.div`
  grid-column: 3 / 23;
  /* iphone 7/8 plus vertical */
  @media (max-width: 736px) {
    grid-column: 2 / 24;
  }
`;

const Lead = styled.div`
  display: grid;
  grid-template-columns: 76px auto 0;
  padding: 45px 0 17px;

  font-family: ${props => props.theme.headerFontFamily};

  @media (max-width: 580px) {
    padding: 10px 0;
    grid-template-columns: 50px auto 50px;
  }
`;

const Logo = styled(Link)`
  display: block;
  text-shadow: none;
  background-image: none;
  img {
    display: block;
    margin: 0;
    overflow: hidden;
    border-radius: 50%;
  }
`;

const LeadText = styled.div`
  padding-left: 30px;
  @media (max-width: 580px) {
    padding-left: 15px;
    align-self: center;
  }
`;

const MobileMenuButton = styled.button`
  font-size: 1.29rem; /* 22px */
  display: none;
  background: none;
  border: none;
  height: 50px;
  @media (max-width: 580px) {
    display: block;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 44px;
  line-height: 1;
  color: #000;

  @media (max-width: 580px) {
    font-size: 30px;
  }
`;

const Subtitle = styled.div`
  font-size: 16px;
  line-height: 1;

  @media (max-width: 580px) {
    display: none;
  }
`;

const NavWrapper = styled.div`
  transform: translateY(26px);
  background: ${props => props.fixed ? "#000" : "none"};

  @media (max-width: 580px) {
    display: none;
  }
`;

const Nav = styled.nav`
  background: #000;
  padding: ${props => props.fixed ? "10px 0 11px" : "15px 20px 16px"};
  transition: ${props => props.fixed ? "padding 0.2s" : "none"};
  line-height: 1;
  grid-column: 3 / 23;
  display: flex;
  /* iphone 7/8 plus vertical */
  @media (max-width: 736px) {
    grid-column: 2 / 24;
  }
`;

const NavLink = styled(Link)`
  margin: 0 30px 0 0;
  display: inline-block;
  vertical-align: middle;
  text-shadow: none;
  background-image: none;
  color: #FFF;
  font-size: 16px;
  line-height: 25px;
  text-transform: uppercase;
  font-family: ${props => props.theme.headerFontFamily};
`;

const NavExtLink = NavLink.withComponent("a");

const NavSocialLink = styled(NavExtLink)`
  margin-left: 10px;
  margin-right: 0;
  font-size: 24px;
  line-height: 25px;
`;

const MobileMenu = styled.div`
  display: ${props => props.open ? "grid" : "none"};
  grid-template-columns: repeat(24, 1fr);
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  height: 100vh;
  z-index: 100;

  ${MobileMenuButton},
  ${Title} {
    color: #FFF;
  }
`;

const MobileMenuList = styled.ul`
  margin: 0;
  list-style: none;
  text-align: center;

  a {
    font-size: 1.29rem; /* 22px */
    margin: 0;
  }
`;

export default Header;
