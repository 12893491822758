import React, {useState} from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Cookies from "js-cookie";

const COOKIE_NAME = "cookie_law";

export function CookieLaw() {
  const [consent, setConsent] = useState(Cookies.get(COOKIE_NAME));

  function close() {
    Cookies.set(COOKIE_NAME, "true", { expires: 365 });
    setConsent("true");
  }

  if (consent) return null;

  return (
    <CookiesWrapper>
      <CookiesContent>
        <CookiesText>
          This website uses cookies for statistical purposes and to better understand its users. Continuing to use this website gives consent to cookies being
          used. You can disable cookies in your browser's settings. Read more in <CookieLink to="/privacy-policy/">Privacy Policy</CookieLink>.
        </CookiesText>
        <CookiesButton onClick={close}>
          Accept
        </CookiesButton>
      </CookiesContent>

    </CookiesWrapper>
  );
}

const CookiesWrapper = styled.div`
  z-index: 99; /* below mobile menu */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #000;
  font-family: "Lucida Grande","Lucida Sans Unicode","Lucida Sans",Geneva,Arial,sans-serif;
  line-height: 1.5;
`;

const CookiesContent = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
`;

const CookiesText = styled.div`
  flex: 1;
`;

const CookiesButton = styled.button`
  border-radius: 4px;
  background: #FFCE00;
  color: #000;
  border: none;
  margin-left: 30px;
  font-size: 16px;
  padding: 5px 20px;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #e6ba00;
  }
`;

const CookieLink = styled(Link)`
  color: #FFF;
  text-shadow: none;
  background-image: none;
  text-decoration: underline;
`;
