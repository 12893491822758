import styled from "styled-components";

export const Frame = styled.div`
  width: 100%;
  max-width: ${props => props.theme.contentWidth}px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  & > * {
    grid-column: 3 / 23;
  }
  /* iphone 7/8 plus vertical */
  @media (max-width: 736px) {
    & > * {
      grid-column: 2 / 24;
    }
  }
`;

export default Frame;
