function postPath(slug) {
  return `/${slug}/`;
}

function homePath(pageNumber) {
  return pageNumber === 1 ? "/" : `/page${pageNumber}/`;
}

function categoryPath(categorySlug) {
  return `/category/${categorySlug}/`;
}

module.exports.postPath = postPath;
module.exports.homePath = homePath;
module.exports.categoryPath = categoryPath;
